import adminProductIds from "../data/adminProductIds";
import hubProductIds from "../data/hubProductIds";

export default (upsellProducts,selectedTreatmentIds,selectedTreatment,isRush) => {
    const cleanUpsellProducts = upsellProducts.filter(p => {
        return !selectedTreatmentIds.includes(p.addOnTreatmentId);
      });

      const addOnTreatmentIdsToExclude = [];
      cleanUpsellProducts.map((cup) => {
        const selectedCasts = selectedTreatment.find((st) => st.id === hubProductIds.CASTS);
        if (selectedCasts) {
          const requirement = selectedCasts.requirements?.find(
            (req) => req.description === 'Which cast do you want?'
          );
          if (
            requirement?.value ===
            'Facially Referenced Mounted and Printable Casts'
          ) {
            addOnTreatmentIdsToExclude.push(hubProductIds.FACIALREFERENCEDIGITALMOUNTINGMODELS);
          }
        }

        // Handle Rush logic
        if (isRush) {
            addOnTreatmentIdsToExclude.push(hubProductIds.RUSH);
        }
      
        // Handle All-on-X logic
        if (cup.addOnTreatmentId === hubProductIds.ALLONXPACKAGE) {
          const selectedAllOnX = selectedTreatment.find((st) => st.id === hubProductIds.ALLONX);
          if (selectedAllOnX.productId === adminProductIds.AllOnXPackage) {
            addOnTreatmentIdsToExclude.push(hubProductIds.ALLONXPACKAGE);
          }
        }

        if (cup.addOnTreatmentId === hubProductIds.DENTOFACIALANALYSIS) {
          const photoCaseRep = selectedTreatment.find((st) => st.id === hubProductIds.PHOTOCASEPRESENTATION);
          if (photoCaseRep && photoCaseRep.productId === adminProductIds.DentoFacialAnalysis) {
            addOnTreatmentIdsToExclude.push(hubProductIds.DENTOFACIALANALYSIS);
          }
        }
      });

      return cleanUpsellProducts.filter(cup => !addOnTreatmentIdsToExclude.includes(cup.addOnTreatmentId));
}