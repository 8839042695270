export default { 
    CrownAndBridge: 'fd51f965-142a-43ea-bd6c-c6c6a7b7922e',
    FCZ: 'ae1a0430-94b0-4a40-bb9c-a380a82a183e',
    PFZ: '54d064ad-c9e0-4a08-bcd1-e11847b6927e',
    CASTS: '8b0a8e05-e6e3-4120-a371-38741051313f',
    FACIALREFERENCEDIGITALMOUNTINGMODELS:'2b5ffbf8-555c-4a3d-bd61-7a836e37fcb6',
    NESTINGFILE: 'f5b1e6ad-52c0-444b-9ea5-8228d778c8eb',
    IMPLANTCROWN: '7fd1e094-1e65-4951-9aff-4d0c5075be3f',
    ALLONX: '88a1ade1-016a-47a4-890e-a2bec7743165',
    ALLONXPACKAGE: '0f6e91bf-171e-4a14-8e30-b6181f8735e1',
    RUSH: '55d25cb2-4166-40ec-b91f-8e9c12973ea4',
    PHOTOCASEPRESENTATION: 'c350f394-54bd-42f3-8d78-41d7f8b99604',
    DENTOFACIALANALYSIS: '4828c26c-eb01-4ccc-a808-d8aed580ae33',
    DEVICES: '802f21bd-2cc6-4788-90b7-df3efcb489de'
}