const searchTerms = {
  "Evident Smile Package": [
    "Smile Analysis & Planning Package",
    "Appended Model on DX Design",
    "Appended Model with Alternating Tooth",
    "DX - Diagnostic wax up",
    "Gingival Reduction Guide",
  ],
  "Lingual Retainer": ["Lingual", "Retainer"],
  "Diagnostic Wax-Up": ["DX", "Diagnostic Wax-up", "Wax up"],
  "Gingival Reduction Guide": ["Gingival Reduction Guide"],
  "Crown & Bridge": [
    "PMMA",
    "Standard temporaries (prepped teeth)",
    "Trial Smile",
    "Virtually prepped shell temps",
    "Temporary crown and bridge",
    "eMax",
    "FCZ - Full contour zirconia",
    "PFZ - Zirconia with fused porcelain (Copings)",
    "FCC/ FGC- Full cast crown",
    "Snap-on",
    "Snap on",
    "Temporaries",
    "Shell Temp",
    "Inlay/Onlay",
    "Inlay",
    "Onlay",
  ],
  Models: [
    "Base Model",
    "Implant Model",
    "Ortho Model",
    "Model with Alignment on semi adjustable Articulator",
  ],
  Aligners: ["Aligner Planning", "Aligner Planning & Fabrication", "Aligner Fabrication Only"],
  "Clear Retainers": [
    "Retainer",
    "Clear Retainer",
    "Final Clear Retainer Fabrication (Set of 3)",
  ],
  "Standard Nightguard": ["Nightguard", "Standard Nightguard"],
  "All on X Implants": ["Implants", "All on X", "All on Four"],
  "Surgical Planning and Guide": [
    "Surgical Planning and Guide",
    "Stackable Guide",
  ],
  "Implant Crowns": [
    "Implants",
    "Custom Implant Abutment",
    //    "Custom Abutment on Ti-Base",
    "Screw-Retained Crown (Direct to Fixture)",
    "Screw-Retained Crown on Ti-Base",
    "Screw-Retained Crown w/ Ti-Base",
    "Abutment",
    "Screw-mentable Crown w/ Custom Abutment",
    //    "Screw-Mentable Crown w/ Custom Abutment on Ti-Base",
    "Cement Retained Crown w/ Custom Abutment",
    //    "Cement Retained Crown w/ Custom Abutment on Ti Base",
    "Crown with Access Hole (Crown Only)",
    "Cement Retained Crown with Custom Abutment",
    "Screw-Retained Crown with Custom Abutment",
    "Screw-Retained Crown w/ Custom Abutment",
    "Custom Abutment (Abutment Only)",
  ],
  "Digital Complete Dentures": ["Full Denture", "Complete Dentures","Jimmy Stegall Dentures","Truedent Denture"],
  "Digital Partial Dentures": [
    "Cast Partial Framework",
    "Removable Partial Dentures",
    "Acrylic Partial Denture",
  ],
  "Custom Tray": ["Custom Tray", "Tray"],
  "Photographic Case Presentation": [
    "Dentofacial Analysis",
    "DFA",
    "Virtual Smile Mock Up with Outline",
    "Smile Mock-up",
  ],
  Casts: [
    "Facially Referenced Mounted and Printable Casts",
    "Hinge Mounted Casts",
    "Casts",
    "Facial Reference Digital Mounting Models",
    "Hinged Mounted Model",
    "KOIS Scan Body Reference Mounted and Printable Casts"
  ],
  Devices: [
    "Kois Deprogrammer",
    "Kois Nightguard",
    "Lucia Jig",
    "Nightguard",
    "Deprogrammer - Standard",
    "Deprogrammer - with anterior coverage",
    "Deprogrammer - with posterior coverage",
    "Maxillary Flat Plane Occlusal Nightguard",
    "Mandibular Flat Plane Occlusal Nightguard",
    "Mandibular Lucia Jig - Platform",
    "Mandibular Lucia Jig - Cobal Balancer",
    "Mandibular Orthopedic Positioning Appliance",
    "Maxillary Orthopedic Positioning Appliance",
  ],
  "CEREC Crown & Bridge" : [ "CEREC Crown & Bridge"
  ],
};
export { searchTerms };
