import hubProductIds from "../data/hubProductIds";

const mapId = (product) => {
    if(!product){
        return {};
    }

    if(product.id){ //parameter is object
        const productId = product.id.toLowerCase();
        if(productId === hubProductIds.CrownAndBridge){
            return mapCrownAndBridge(product)
        }
        if(productId === hubProductIds.PHOTOCASEPRESENTATION){
            return hubProductIds.DENTOFACIALANALYSIS
        }

        if(productId === hubProductIds.DEVICES){
            return mapDevices(product);
        }

        return product.id;
    } else { //parameter passed is GUID instead of object
        if(product === hubProductIds.FACIALREFERENCEDIGITALMOUNTINGMODELS){
            return hubProductIds.CASTS;
        }

        if(product === hubProductIds.DENTOFACIALANALYSIS){
            return hubProductIds.PHOTOCASEPRESENTATION;
        }
        return product;
    }
    
    
}

const mapCrownAndBridge = (product) => {
    const requirements = product.requirements.filter(r => r.description === 'Restoration Type');
    if(!requirements.length){
        return product.id;
    }

    const inputs = requirements[0];
    if(inputs.value === 'Final'){
        if(inputs.type === 'FCZ'){
            return hubProductIds.FCZ
        } else if(inputs.type === 'PFZ'){
            return hubProductIds.PFZ
        }
    }
    return product.id;
}

const mapDevices = (product) => {
    console.log('product.requirements',product.requirements);
    const requirementsIndex = product.requirements.find(r => r.description === 'Which Protocol do you want?');
    console.log('requirementsIndex',requirementsIndex)
    if(!requirementsIndex){
        return false;
    }
    return requirementsIndex.value === 'Kois Deprogrammer' ? product.id : '';
}

export default mapId;