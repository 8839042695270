import React from "react";
import {
  IconButton,
  Dialog,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import jimmyStegallDenturesLogo from "../../../images/jimmy-stegall-dentures.webp";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const JimmyStegallDenturesModal = ({ isOpen, handleClose }) => {
  const closeDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
      sx={{ textAlign: "center", marginBottom: "15px" }}
      PaperProps={{ style: { padding: "15px" } }}
    >
      
      <DialogTitle sx={{ fontWeight: "bold" , border:"solid 0px red",paddingTop:"0px", paddingBottom:"10px !important", height:"70px" }} id="alert-dialog-title"> 
        <h2>Jimmy Stegall Dentures</h2> 
        <IconButton onClick={closeDialog} sx={{marginTop:"-160px", marginLeft:"90%"}}>
          <CloseIcon />
        </IconButton> 
      </DialogTitle>
      <DialogContent>
        <>
          <Box>
            <img src={jimmyStegallDenturesLogo} style={{ width: "95%", marginTop:"-20px" }} />
          </Box>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "left", fontSize: "14px", fontWeight: "400" }}
          > 
          <br/> 
            <p>
              <i>Jimmy Stegall Dentures</i> on Evident Hub brings a revolutionary denture workflow designed by industry veteran Jimmy Stegall. With 40 years of expertise, Jimmy—recently named 2024 NADL Educator of the Year—has led a premier dental lab, educated thousands, and pioneered innovations in implant and prosthetic restoration. His unique workflow ensures a superior fit and enables dentists to deliver dentures in just two appointments, reducing patient discomfort and rework. Elevate your practice with predictable, time-saving results crafted by an authority who understands the clinical and operational demands of dentistry.
            </p>
            <p>
              Learn more about Jimmy Stegall: <a href="https://heretoservedental.com/">https://heretoservedental.com/</a>
            </p>
            
          </DialogContentText>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default JimmyStegallDenturesModal;
