import React, { useState } from 'react';
import Dropzone from "react-dropzone";
import {
  Box,
  Grid,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  Checkbox,
  Typography,
} from "@mui/material";

import setFilePhoto from "../../../../images/set_file_photo.svg";

import A1Shade from "../../../../images/Shade/A1-Shade.png";
import BleachShade from "../../../../images/Shade/bleach-shade.png";
import D2Shade from "../../../../images/Shade/d2-shade.png";

import SmileA1 from "../../../../images/smile-shade/A1-shade.png";
import SmileB1 from "../../../../images/smile-shade/b1-shade.png";
import SmileC1 from "../../../../images/smile-shade/bleach-shade.png";
import SmileD2 from "../../../../images/smile-shade/C1-shade.png";
import SmileBleach from "../../../../images/smile-shade/d2-shade.png";

import NaturalPosition from "../../../../images/shape/position-natural-new.png";
import OvoidPosition from "../../../../images/shape/position-ovoid.png";
import SquarePosition from "../../../../images/shape/position-square.png";

import NaturalDenture from "../../../../images/festooning/natural.png";
import DelicateDenture from "../../../../images/festooning/delicate.png";
import IntenseDenture from "../../../../images/festooning/intense.png";

import AggressiveStyle from "../../../../images/toothStyles/aggressiveStyle.png";
import DominantStyle from "../../../../images/toothStyles/dominantStyle.png";
import EnhancedStyle from "../../../../images/toothStyles/enhancedStyle.png";
import FocusedStyle from "../../../../images/toothStyles/focusedStyle.png";
import FunctionalStyle from "../../../../images/toothStyles/functionalStyle.png";
import HollywoodStyle from "../../../../images/toothStyles/hollywoodStyle.png";
import MatureStyle from "../../../../images/toothStyles/matureStyle.png";
import NaturalStyle from "../../../../images/toothStyles/naturalStyle.png";
import OvalStyle from "../../../../images/toothStyles/ovalStyle.png";
import SoftenedStyle from "../../../../images/toothStyles/softenedStyle.png";
import VigorousStyle from "../../../../images/toothStyles/vigorousStyle.png";
import YouthfulStyle from "../../../../images/toothStyles/youthfulStyle.png";
import AdditionalFiles from "../../../../images/additionalFiles.svg";

import useSegment from "../../../../hooks/useSegment";

// import CementRetainedCrown from "../../../../images/crownOrAbutment/cement-retained-crown.png";
// import CrownWithAccessHole from "../../../../images/crownOrAbutment/crown-with-access-hole.png";
// import CustomAbutment from "../../../../images/crownOrAbutment/custom-abutment.png";
import ScrewMandible from "../../../../images/crownOrAbutment/screw-mandible.png";
// import ScrewRetainedCrown from "../../../../images/crownOrAbutment/screw-retained-crown-custom.png";

import CustomAbutmentOne from "../../../../images/crownOrAbutment/custom-abutment-one-piece.png";
import CustomAbutmentTwo from "../../../../images/crownOrAbutment/custom-abutment-two-piece.png";
import CementRetaindCrownOne from "../../../../images/crownOrAbutment/cement-retained-crown-one-piece.png";
import CementRetaindCrownTwo from "../../../../images/crownOrAbutment/cement-retained-crown-two-piece.png";
import ScrewRetainedCrownOne from "../../../../images/crownOrAbutment/screw-retained-crown-one-piece.png";
import ScrewRetainedCrownTwo from "../../../../images/crownOrAbutment/screw-retained-crown-two-piece.png";
import ScrewMandibleOne from "../../../../images/crownOrAbutment/screw-mandible-one-piece.png";
import ScrewMandibleTwo from "../../../../images/crownOrAbutment/screw-mandible-two-piece.png";
import ScrewRetainedCrownOnTiBase from "../../../../images/crownOrAbutment/07182023/Screw-RetainedCrownonTitaniumBase.png";
import ScrewRetainedCrownDirectToFixture from "../../../../images/crownOrAbutment/07182023/screw-retained-crown-direct-to-fixture.png";
import CustomAbutment from "../../../../images/crownOrAbutment/07182023/Custom-Abutment-Abutment-only.png";
import CrownWithAccessHole from "../../../../images/crownOrAbutment/07182023/Crown-with-access-hole-Crown-only.png";
// import ScrewRetainedCrown from "../../../../images/crownOrAbutment/Screw-Retained--Crown.png";
import CementRetainedCrown from "../../../../images/crownOrAbutment/07162023/cement-retained-crown-with-custom-abutment-on-titanium-base.png";
import CementRetainedCrownWithCustomAbutment from "../../../../images/crownOrAbutment/07182023/CementRetaineCrownWCA.png";
import ScrewRetainedCrownWithCustomAbutment from "../../../../images/crownOrAbutment/07182023/Screw-RetainedCrownwithCustomAbutment.png";

//

import ProfileConcave from "../../../../images/emergenceProfile/concave.png";
import ProfileStraight from "../../../../images/emergenceProfile/straight.png";
import ProfileConvex from "../../../../images/emergenceProfile/convex.png";

import ToothService from "../../../../services/toothChart.service";

import PatientService from "../../../../services/patient.service";

import SelectToothRestorationModal from "../common/selectToothRestorationModal/selectToothRestorationModal";

import ToothChartComponent from "../../../requirementComponents/toothChartComponent";
import FilesNeededForSmilePackageComponent from "../../../requirementComponents/filesNeededForSmilePackageComponent";
import ImagesListComponent from "../../../requirementComponents/imagesListComponent";
import CheckboxListComponent from "../../../requirementComponents/checkboxListComponent";
import ListWithOtherSpecifyComponent from "../../../requirementComponents/listWithOtherSpecifyComponent";
import ListWithChildComponent from "../../../requirementComponents/listWithChildComponent";

import { validateYupSchema } from "formik";
import RequirementBoxCount from "../../../common/RequirementBoxCount";
import SelectListComponent from "../../../requirementComponents/selectListComponent";
import DesiredBaseThickness from "../../../requirementComponents/desiredBaseThickness";
import TruedentInfoModal from "../../../common/modal/truedentInfoModal";
import ArtisanInfoModal from "../../../common/modal/artisanInfoModal";
import { useSelector } from "react-redux";
import JimmyStegallDenturesModal from '../../../common/modal/JimmyStegallDenturesModal';


function TreatmentForms({
  treatment,
  patientId,
  handleTreatmentUpdate,
  notion,
  isComponentVisible,
  setIsComponentVisible,
  personnelId,
  additionalOrderDetails,
  isCancelled
}) {

  const [treatmentDetails, setTreatmentDetails] = React.useState(treatment);
  const [showCheckBoxListOther, setCheckBoxListOther] = React.useState(true);
  const [openTrudentInfoModal,setOpenTrudentInfoModal] = React.useState(false);
  const [openJimmyStegallDenturesModal,setOpenJimmyStegallDenturesModal] = React.useState(false);
  const [isSelectedArtisan,setIsSelectedArtisan] = React.useState(false);
  const [truedentModalOption,setTruedentModalOption] = React.useState(0);
  const [isAutoSelectArtisan,setIsAutoSelectArtisan] = React.useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const [isUpdatingNotes,setIsUpdatingNotes] = React.useState(false);

  const segment = useSegment();

  const handCloseTrudentInfo = () =>{ 
  
    setOpenTrudentInfoModal(false);
  };

  const handCloseJimmyStegallDenturesModal = () =>{ 
  
    setOpenJimmyStegallDenturesModal(false);
  };

  const handCloseArtisanInfo = () =>{
    setIsSelectedArtisan(false);
  };

  

  React.useEffect(()=>{
    refreshFormForTruedent();
  },[isAutoSelectArtisan]);
 

  const handleSelectArtisan = () =>{
    

    setTreatmentDetails((prevDetails) => {
      const updatedRequirements = prevDetails.requirements.map((requirement) => {
        if (requirement.description === 'Are you fabricating In-house ?') {
          return {
            ...requirement,
            value: 'No',
            lab: 'Artisan Dental Laboratory'
          };
        }
        return requirement;
      });

      return {
        ...prevDetails,
        requirements: updatedRequirements,
      };
    }); 
    setOpenTrudentInfoModal(false);
    
    setIsAutoSelectArtisan(!isAutoSelectArtisan);
 
  }


  const festooning = [
    {
      name: "Delicate",
      image: DelicateDenture,
    },
    {
      name: "Natural",
      image: NaturalDenture,
    },
    {
      name: "Intense",
      image: IntenseDenture,
    },
  ];
  const shades = [
    {
      name: "Natural",
      image: A1Shade,
    },
    {
      name: "Bleach",
      image: BleachShade,
    },
    {
      name: "D2",
      image: D2Shade,
    },
  ];
  const toothStyles = [
    {
      name: "Aggressive Style",
      image: AggressiveStyle,
    },
    {
      name: "Dominant Style",
      image: DominantStyle,
    },
    {
      name: "Oval Style",
      image: OvalStyle,
    },
    {
      name: "Softened Style",
      image: SoftenedStyle,
    },
    {
      name: "Enhanced Style",
      image: EnhancedStyle,
    },
    {
      name: "Vigorous Style",
      image: VigorousStyle,
    },
    {
      name: "Youthful Style",
      image: YouthfulStyle,
    },
    {
      name: "Functional Style",
      image: FunctionalStyle,
    },
    {
      name: "Hollywood Style",
      image: HollywoodStyle,
    },
    {
      name: "Focused Style",
      image: FocusedStyle,
    },
    {
      name: "Mature Style",
      image: MatureStyle,
    },
    {
      name: "Natural Style",
      image: NaturalStyle,
    },
  ];

  const smileShade = [
    {
      name: "Bleach",
      image: SmileC1,
    },
    {
      name: "B1",
      image: SmileB1,
    },
    {
      name: "A1",
      image: SmileA1,
    },
    {
      name: "C1",
      image: SmileD2,
    },

    {
      name: "D2",
      image: SmileBleach,
    },
  ];

  const smileShape = [
    {
      name: "Natural",
      image: NaturalPosition,
    },
    {
      name: "Ovoid",
      image: OvoidPosition,
    },
    {
      name: "Square",
      image: SquarePosition,
    },
  ];

  const crownOrAbutment = [
    {
      name: "Screw-Retained Crown on Ti-Base",
      item: "Screw-Retained Crown on Ti-Base",
      image: ScrewRetainedCrownOnTiBase,
    },
    {
      name: "Screw-Retained Crown (Direct to Fixture)",
      item: "Screw-Retained Crown (Direct to Fixture)",
      image: ScrewRetainedCrownDirectToFixture,
    },
    {
      name: "Cement Retained Crown w/ Custom Abutment",
      item: "Cement Retained Crown w/ Custom Abutment",
      image: CementRetainedCrownWithCustomAbutment,
    },
    {
      name: "Screw-Retained Crown w/ Custom Abutment",
      item: "Screw-Retained Crown w/ Custom Abutment",
      image: ScrewRetainedCrownWithCustomAbutment,
    },
    {
      name: "Custom Abutment (Abutment Only)",
      item: "Custom Abutment (Abutment Only)",
      image: CustomAbutment,
    },
    {
      name: "Crown with Access Hole (Crown Only)",
      item: "Crown with Access Hole (Crown Only)",
      image: CrownWithAccessHole,
    },
  ];

  const emergenceProfile = [
    {
      name: "Concave",
      item: "Concave",
      image: ProfileConcave,
    },
    {
      name: "Straight",
      item: "Straight",
      image: ProfileStraight,
    },
    {
      name: "Convex",
      item: "Convex",
      image: ProfileConvex,
    },
  ];

  const onTiBase = {
    "Custom Abutment": {
      1: CustomAbutmentOne,
      2: CustomAbutmentTwo,
    },
    "Cement Retained Crown with Custom Abutment": {
      1: CementRetaindCrownOne,
      2: CementRetaindCrownTwo,
    },
    "Screw-Retained Crown (crown only)": {
      1: ScrewRetainedCrownOne,
      2: ScrewRetainedCrownTwo,
    },
    "Screw-Mentable Crown with Custom Abutment": {
      1: ScrewMandibleOne,
      2: ScrewMandibleTwo,
    },
  };

  /* const onTiBaseValues = () => {
    const curr = treatmentDetails.requirements.find(
      (r) => r.description === "Is this Crown Only or Crown + Abutment?"
    );
    if (!curr || !curr.value) return [];

    return [
      {
        name: "Yes (Two-piece)",
        item: "Yes (Two-piece)",
        image: onTiBase[curr.value][2],
      },
      {
        name: "No (One-piece)",
        item: "No (One-piece)",
        image: onTiBase[curr.value][1],
      },
    ];
  }; */

  React.useEffect(() => {
    treatmentDetails.files = treatment.files;
    setTreatmentDetails(treatmentDetails);
  }, [treatment.files]);

  const refreshForm = () => { 
  
    let treatmentDetailsCopy = Object.assign([], treatmentDetails);

    setTreatmentDetails(treatmentDetailsCopy); 

    handleTreatmentUpdate(treatmentDetailsCopy);

    if (treatment.toothNumbers.length > 0) {
      let numbers = [];

      treatment.toothNumbers.forEach((x) => {
        numbers.push(x.tooth);
      });

      numbers.forEach((number) =>
        ToothService.setToothColorToSelected(number, treatment.id)
      );
    }

    if (treatment.description === "Evident Smile Package") {
      setIsComponentVisible([
        {
          description: "Measurement",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Surgical Planning and Guide") {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: false,
        },
        {
          description: "Arch Design",
          visible: false,
        },
        {
          description: "Does this include a restorative design?",
          visible: false,
        },
        {
          description: "Do you want to add a Stackable Guide?",
          visible: false,
        },
        {
          description: "Alignment On Semi-Adjustable Articulator",
          visible: false,
        },
        {
          description: "Articulator",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Implant Crowns") {
      setIsComponentVisible([
        {
          description: "Collar Height Buccal",
          visible: false,
        },
        {
          description: "Collar Height Lingual",
          visible: false,
        },
        {
          description: "Scanbody",
          visible: false,
        },
        {
          description: "Kind of Implant",
          visible: false,
        },
        {
          description: "Emergence Profile",
          visible: false,
        },
        {
          description: "Tissue Pressure",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Devices") {
      setIsComponentVisible([
        {
          description: "Deprogrammer Options",
          visible: false,
        },
        {
          description: "Nightguard Options",
          visible: false,
        },
        {
          description: "Mandibular Anterior Jig Options",
          visible: false,
        },
        {
          description: "Orthopedic Appliance Options",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Models") {
      setIsComponentVisible([
        {
          description: "Arch Design",
          visible: false,
        },
        {
          description: "Does this include a restorative design?",
          visible: false,
        },
        {
          description: "Do you want to add a Stackable Guide?",
          visible: false,
        },
        {
          description: "Alignment On Semi-Adjustable Articulator",
          visible: false,
        },
        {
          description: "Articulator",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Digital Partial Dentures") {
      setIsComponentVisible([
        {
          description: "Tooth Arch",
          visible: false,
        },
        {
          description: "Type of Framework",
          visible: false,
        },
        {
          description: "Manufacturing Material",
          visible: false,
        },
        {
          description: "Design Teeth For Partial Rests",
          visible: false,
        },
        {
          description: "Mesial Occlusal Tooth #",
          visible: false,
        },
        {
          description: "Distal Occlusal Tooth #",
          visible: false,
        },
        {
          description: "Cingulum Tooth #",
          visible: false,
        },
        {
          description: "Ball Tooth #",
          visible: false,
        },
        {
          description: "Circumferential Tooth #",
          visible: false,
        },
        {
          description: "I-Bar Tooth #",
          visible: false,
        },
        {
          description: "T-Bar Tooth #",
          visible: false,
        },
        {
          description: "RPA Tooth #",
          visible: false,
        },
        {
          description: "RPI Tooth #",
          visible: false,
        },
        {
          description: "Ring Tooth #",
          visible: false,
        },
        {
          description: "Wrought Wire Tooth #",
          visible: false,
        },
        {
          description: "Flexible Combo Tooth #",
          visible: false,
        },
        {
          description: "Provide Drawing Instructions (Optional)",
          visible: false,
        },
        {
          description: "Library To Be Used",
          visible: false,
        },
        {
          description: "Teeth Segmentation",
          visible: false,
        },
        {
          description: "Denture Output",
          visible: false,
        },
        {
          description:
            "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
          visible: false,
        },
        {
          description: "Support Bar",
          visible: false,
        },
        {
          description: "Where To Place Clasps",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Photographic Case Presentation") {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: false,
        },
        {
          description: "Tooth Shade",
          visible: false,
        },
        {
          description: "Tooth Shape",
          visible: false,
        },
        {
          description: "Max Incisal Edge Position",
          visible: false,
        },
        {
          description: "Platform Height",
          visible: false,
        },
        {
          description: "Gingival Levels",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Aligners") {
      setIsComponentVisible([
        {
          description: "Hollow or Solid Models?",
          visible: true,
        },
        {
          description: "Do you need your models based for vertical printing?",
          visible: true,
        },
        {
          description: "Are you using the GRAPHY workflow?",
          visible: false,
        },
        {
          description: "Are you fabricating In-house?",
          visible: false,
        },
        {
          description: "Does your patient have any special considerations?",
          visible: false,
        },
        {
          description: "The STL files I want to have are",
          visible: false,
        },
        {
          description: "What is the patient's main complaint?",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Aligner Fabrication Only") {
      alert("Aligner Fabrication Only");
      setIsComponentVisible([
        {
          description: "The STL files I want to have are",
          visible: true,
        },
        {
          description: "What is the patient's main complaint?",
          visible: true,
        },
      ]);
    } else if (treatment.description === "Clear Retainers") {
      setIsComponentVisible([
        {
          description: "Are you using the GRAPHY workflow?",
          visible: false,
        },
      ]);
    } else {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: true,
        },
        {
          description: "Arch Design",
          visible: true,
        },
        {
          description: "Does this include a restorative design?",
          visible: true,
        },
        {
          description: "Do you want to add a Stackable Guide?",
          visible: true,
        },
        {
          description: "Alignment On Semi-Adjustable Articulator",
          visible: true,
        },
        {
          description: "Articulator",
          visible: true,
        },
        {
          description: "Tooth Arch",
          visible: true,
        },
        {
          description: "Type of Framework",
          visible: true,
        },
        {
          description: "Manufacturing Material",
          visible: true,
        },
        {
          description: "Design Teeth For Partial Rests",
          visible: true,
        },
        {
          description: "Mesial Occlusal Tooth #",
          visible: true,
        },
        {
          description: "Distal Occlusal Tooth #",
          visible: true,
        },
        {
          description: "Cingulum Tooth #",
          visible: true,
        },
        {
          description: "Ball Tooth #",
          visible: true,
        },
        {
          description: "Circumferential Tooth #",
          visible: true,
        },
        {
          description: "I-Bar Tooth #",
          visible: true,
        },
        {
          description: "T-Bar Tooth #",
          visible: true,
        },
        {
          description: "RPA Tooth #",
          visible: true,
        },
        {
          description: "RPI Tooth #",
          visible: true,
        },
        {
          description: "Ring Tooth #",
          visible: true,
        },
        {
          description: "Wrought Wire Tooth #",
          visible: true,
        },
        {
          description: "Flexible Combo Tooth #",
          visible: true,
        },
        {
          description: "Provide Drawing Instructions (Optional)",
          visible: true,
        },
        {
          description: "Library To Be Used",
          visible: true,
        },
        {
          description: "Teeth Segmentation",
          visible: true,
        },
        {
          description: "Denture Output",
          visible: true,
        },
        {
          description:
            "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
          visible: true,
        },
        {
          description: "Support Bar",
          visible: true,
        },
        {
          description: "Where To Place Clasps",
          visible: true,
        },
        {
          description: "Tooth Shade",
          visible: true,
        },
        {
          description: "Tooth Shape",
          visible: true,
        },
        {
          description: "Max Incisal Edge Position",
          visible: true,
        },
        {
          description: "Platform Height",
          visible: true,
        },
        {
          description: "Gingival Levels",
          visible: true,
        },
        {
          description: "Deprogrammer Options",
          visible: true,
        },
        {
          description: "Nightguard Options",
          visible: true,
        },
        {
          description: "Mandibular Anterior Jig Options",
          visible: true,
        },
        {
          description: "Orthopedic Appliance Options",
          visible: true,
        },
        {
          description: "Hollow or Solid Models?",
          visible: true,
        },
        {
          description: "Do you need your models based for vertical printing?",
          visible: true,
        },
        {
          description: "Are you using the GRAPHY workflow?",
          visible: true,
        },
        {
          description: "Are you fabricating In-house?",
          visible: true,
        },
      ]);
    }

};


const refreshFormForTruedent = () => { 
    
    let treatmentDetailsCopy = Object.assign([], treatmentDetails);

    setTreatmentDetails(treatmentDetailsCopy); 

    handleTreatmentUpdate(treatmentDetailsCopy);

    if (treatmentDetails.description === "Digital Complete Dentures")
      {   
        let isTruedent = treatmentDetails.requirements.some(
          (x) => x.value === "Truedent Denture" && x.description ==="Type of Complete Dentures"
        );

        let isNotfabricatingInHouse = treatmentDetails.requirements.some(
          (x) => x.value === "No" && x.description ==="Are you fabricating In-house ?"
        );  

            if(isTruedent && isNotfabricatingInHouse)
            {
              let isLabArtisan = treatmentDetails.requirements.some(
                (x) => x.lab === "Artisan Dental Laboratory" && x.description ==="Are you fabricating In-house ?"
              );

              setIsSelectedArtisan(isLabArtisan);

              let newArr = isComponentVisible.map((item, i) => {
                if (
                  item.description === "Type of Complete Dentures" || 
				          item.description === "Tooth Mould/Shape Anterior" ||
                  item.description === "Tooth Mould/Shape Posterior" ||
                  item.description === "Papillameter High Lip Line" ||
                  item.description === "Papillameter Rest" ||
                  item.description === "Vertical Dimension of Occlusion" ||
                  item.description === "Ridge Relationship" ||
                  item.description === "Workflow" ||
                  item.description === "Occlusal Scheme" ||
                  item.description === "Reference" ||
                  item.description === "Tooth Color" ||
                  item.description === "Gingival Shades" ||
                  item.description === "Are you fabricating In-house ?" ||
                  item.description === "RX Notes"
                ) {
                  return {
                    ...item,
                    visible: true,
                  };
                } else {
                  return { ...item, visible: false };
                }
              });
    
              setIsComponentVisible(newArr);
            }
            else if(isTruedent)
            {
              
              setIsSelectedArtisan(false);
              setTruedentModalOption(1);
              setOpenTrudentInfoModal(true);
              let newArr = isComponentVisible.map((item, i) => {
                if (
                  item.description === "Tooth Mould/Shape Anterior" ||
                  item.description === "Tooth Mould/Shape Posterior" ||
                  item.description === "Papillameter High Lip Line" ||
                  item.description === "Papillameter Rest" ||
                  item.description === "Vertical Dimension of Occlusion" ||
                  item.description === "Ridge Relationship" ||
                  item.description === "Type of Complete Dentures" || 
                  item.description === "Workflow" ||
                  item.description === "Occlusal Scheme" ||
                  item.description === "Reference" || 
                  item.description === "Are you fabricating In-house ?" ||
                  item.description === "RX Notes"
                ) 
                {
                  return {
                    ...item,
                    visible: true,
                  };
                } else {
                  return { ...item, visible: false };
                }
              });

              setIsComponentVisible(newArr);

            }

      }

};





  React.useEffect(() => {
  
    let treatmentDetailsCopy = Object.assign([], treatmentDetails);

    setTreatmentDetails(treatmentDetailsCopy); 

    handleTreatmentUpdate(treatmentDetailsCopy);

    if (treatment.toothNumbers.length > 0) {
      let numbers = [];

      treatment.toothNumbers.forEach((x) => {
        numbers.push(x.tooth);
      });

      numbers.forEach((number) =>
        ToothService.setToothColorToSelected(number, treatment.id)
      );
    }

    if (treatment.description === "Evident Smile Package") {
      setIsComponentVisible([
        {
          description: "Measurement",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Surgical Planning and Guide") {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: false,
        },
        {
          description: "Arch Design",
          visible: false,
        },
        {
          description: "Does this include a restorative design?",
          visible: false,
        },
        {
          description: "Do you want to add a Stackable Guide?",
          visible: false,
        },
        {
          description: "Alignment On Semi-Adjustable Articulator",
          visible: false,
        },
        {
          description: "Articulator",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Implant Crowns") {
      setIsComponentVisible([
        {
          description: "Collar Height Buccal",
          visible: false,
        },
        {
          description: "Collar Height Lingual",
          visible: false,
        },
        {
          description: "Scanbody",
          visible: false,
        },
        {
          description: "Kind of Implant",
          visible: false,
        },
        {
          description: "Emergence Profile",
          visible: false,
        },
        {
          description: "Tissue Pressure",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Devices") {
      setIsComponentVisible([
        {
          description: "Deprogrammer Options",
          visible: false,
        },
        {
          description: "Nightguard Options",
          visible: false,
        },
        {
          description: "Mandibular Anterior Jig Options",
          visible: false,
        },
        {
          description: "Orthopedic Appliance Options",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Models") {
      setIsComponentVisible([
        {
          description: "Arch Design",
          visible: false,
        },
        {
          description: "Does this include a restorative design?",
          visible: false,
        },
        {
          description: "Do you want to add a Stackable Guide?",
          visible: false,
        },
        {
          description: "Alignment On Semi-Adjustable Articulator",
          visible: false,
        },
        {
          description: "Articulator",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Digital Partial Dentures") {
      setIsComponentVisible([
        {
          description: "Tooth Arch",
          visible: false,
        },
        {
          description: "Type of Framework",
          visible: false,
        },
        {
          description: "Manufacturing Material",
          visible: false,
        },
        {
          description: "Design Teeth For Partial Rests",
          visible: false,
        },
        {
          description: "Mesial Occlusal Tooth #",
          visible: false,
        },
        {
          description: "Distal Occlusal Tooth #",
          visible: false,
        },
        {
          description: "Cingulum Tooth #",
          visible: false,
        },
        {
          description: "Ball Tooth #",
          visible: false,
        },
        {
          description: "Circumferential Tooth #",
          visible: false,
        },
        {
          description: "I-Bar Tooth #",
          visible: false,
        },
        {
          description: "T-Bar Tooth #",
          visible: false,
        },
        {
          description: "RPA Tooth #",
          visible: false,
        },
        {
          description: "RPI Tooth #",
          visible: false,
        },
        {
          description: "Ring Tooth #",
          visible: false,
        },
        {
          description: "Wrought Wire Tooth #",
          visible: false,
        },
        {
          description: "Flexible Combo Tooth #",
          visible: false,
        },
        {
          description: "Provide Drawing Instructions (Optional)",
          visible: false,
        },
        {
          description: "Library To Be Used",
          visible: false,
        },
        {
          description: "Teeth Segmentation",
          visible: false,
        },
        {
          description: "Denture Output",
          visible: false,
        },
        {
          description:
            "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
          visible: false,
        },
        {
          description: "Support Bar",
          visible: false,
        },
        {
          description: "Where To Place Clasps",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Photographic Case Presentation") {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: false,
        },
        {
          description: "Tooth Shade",
          visible: false,
        },
        {
          description: "Tooth Shape",
          visible: false,
        },
        {
          description: "Max Incisal Edge Position",
          visible: false,
        },
        {
          description: "Platform Height",
          visible: false,
        },
        {
          description: "Gingival Levels",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Aligners") {
      setIsComponentVisible([
        {
          description: "Hollow or Solid Models?",
          visible: true,
        },
        {
          description: "Do you need your models based for vertical printing?",
          visible: true,
        },
        {
          description: "Are you using the GRAPHY workflow?",
          visible: false,
        },
        {
          description: "Are you fabricating In-house?",
          visible: false,
        },
        {
          description: "Does your patient have any special considerations?",
          visible: false,
        },
        {
          description: "The STL files I want to have are",
          visible: false,
        },
        {
          description: "What is the patient's main complaint?",
          visible: false,
        },
      ]);
    } else if (treatment.description === "Aligner Fabrication Only") {
      alert("Aligner Fabrication Only");
      setIsComponentVisible([
        {
          description: "The STL files I want to have are",
          visible: true,
        },
        {
          description: "What is the patient's main complaint?",
          visible: true,
        },
      ]);
    } else if (treatment.description === "Clear Retainers") {
      setIsComponentVisible([
        {
          description: "Are you using the GRAPHY workflow?",
          visible: false,
        },
      ]);
    }else if (treatment.description === "CEREC Crown & Bridge") {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: false,
        } 
        ,
        {
            description: "Radial Spacer",
            visible: false,
        } 
        ,
        {
            description: "Occlusal Spacer",
            visible: false,
        },
        {
            description: "Proximal Contact Strength",
            visible: false,
        } ,
        {
            description: "Occlusal Contact Strength",
            visible: false,
        } ,
        {
            description: "Dynamic Contact Strength",
            visible: false,
        }  ,
        {
            description: "Minimal Radial Thickness",
            visible: false,
        }  ,
        {
            description: "Minimal Occlusal Thickness",
            visible: false,
        } ,
        {
            description: "Margin Thickness",
            visible: false,
        } ,
        {
            description: "Width of Ramp",
            visible: false,
        } ,
        {
            description: "Angle of Ramp",
            visible: false,
        }
      ]);
    } else {
      setIsComponentVisible([
        {
          description: "Tooth Chart",
          visible: true,
        },
        {
          description: "Arch Design",
          visible: true,
        },
        {
          description: "Does this include a restorative design?",
          visible: true,
        },
        {
          description: "Do you want to add a Stackable Guide?",
          visible: true,
        },
        {
          description: "Alignment On Semi-Adjustable Articulator",
          visible: true,
        },
        {
          description: "Articulator",
          visible: true,
        },
        {
          description: "Tooth Arch",
          visible: true,
        },
        {
          description: "Type of Framework",
          visible: true,
        },
        {
          description: "Manufacturing Material",
          visible: true,
        },
        {
          description: "Design Teeth For Partial Rests",
          visible: true,
        },
        {
          description: "Mesial Occlusal Tooth #",
          visible: true,
        },
        {
          description: "Distal Occlusal Tooth #",
          visible: true,
        },
        {
          description: "Cingulum Tooth #",
          visible: true,
        },
        {
          description: "Ball Tooth #",
          visible: true,
        },
        {
          description: "Circumferential Tooth #",
          visible: true,
        },
        {
          description: "I-Bar Tooth #",
          visible: true,
        },
        {
          description: "T-Bar Tooth #",
          visible: true,
        },
        {
          description: "RPA Tooth #",
          visible: true,
        },
        {
          description: "RPI Tooth #",
          visible: true,
        },
        {
          description: "Ring Tooth #",
          visible: true,
        },
        {
          description: "Wrought Wire Tooth #",
          visible: true,
        },
        {
          description: "Flexible Combo Tooth #",
          visible: true,
        },
        {
          description: "Provide Drawing Instructions (Optional)",
          visible: true,
        },
        {
          description: "Library To Be Used",
          visible: true,
        },
        {
          description: "Teeth Segmentation",
          visible: true,
        },
        {
          description: "Denture Output",
          visible: true,
        },
        {
          description:
            "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
          visible: true,
        },
        {
          description: "Support Bar",
          visible: true,
        },
        {
          description: "Where To Place Clasps",
          visible: true,
        },
        {
          description: "Tooth Shade",
          visible: true,
        },
        {
          description: "Tooth Shape",
          visible: true,
        },
        {
          description: "Max Incisal Edge Position",
          visible: true,
        },
        {
          description: "Platform Height",
          visible: true,
        },
        {
          description: "Gingival Levels",
          visible: true,
        },
        {
          description: "Deprogrammer Options",
          visible: true,
        },
        {
          description: "Nightguard Options",
          visible: true,
        },
        {
          description: "Mandibular Anterior Jig Options",
          visible: true,
        },
        {
          description: "Orthopedic Appliance Options",
          visible: true,
        },
        {
          description: "Hollow or Solid Models?",
          visible: true,
        },
        {
          description: "Do you need your models based for vertical printing?",
          visible: true,
        },
        {
          description: "Are you using the GRAPHY workflow?",
          visible: true,
        },
        {
          description: "Are you fabricating In-house?",
          visible: true,
        },
      ]);
    }
  }, []);

  React.useEffect(() => {
    const isInlayOnlay = treatmentDetails.toothNumbers.find(
      (tooth) => tooth.restorationType === "Inlay/Onlay"
    );
    const isCrowns = treatmentDetails.toothNumbers.find(
      (tooth) => tooth.restorationType === "Crowns"
    );
    const isPontic = treatmentDetails.toothNumbers.find(
      (tooth) => tooth.restorationType === "Pontic"
    );
    const isVeneers = treatmentDetails.toothNumbers.find(
      (tooth) => tooth.restorationType === "Veneers"
    );
    if (treatmentDetails.description === "Crown & Bridge") {
      if (isInlayOnlay) {
        setIsComponentVisible([
          {
            description: "Occlusal Contact",
            visible: true,
          },
        ]);
      } else {
        setIsComponentVisible([
          {
            description: "Occlusal Contact",
            visible: false,
          },
        ]);
      }
      if (isCrowns || isPontic || isVeneers) {
        setIsComponentVisible([
          {
            description: "Misaligned Teeth Correction",
            visible: true,
          },
          {
            description: "Gingival Contouring",
            visible: true,
          },
          {
            description: "Vertical Dimension for Occlusion",
            visible: true,
          },
          {
            description: "Restoration Type",
            visible: true,
          },
          {
            description: "Max Preferred Length of Centrals",
            visible: true,
          },
          {
            description: "Do you have an existing wax-up to follow?",
            visible: true,
          },
        ]);
      }
      if (!isCrowns && !isPontic && !isVeneers) {
        setIsComponentVisible([
          {
            description: "Misaligned Teeth Correction",
            visible: false,
          },
          {
            description: "Gingival Contouring",
            visible: false,
          },
          {
            description: "Vertical Dimension for Occlusion",
            visible: false,
          },
          {
            description: "Restoration Type",
            visible: false,
          },
          {
            description: "Max Preferred Length of Centrals",
            visible: false,
          },
          {
            description: "Do you have an existing wax-up to follow?",
            visible: false,
          },
        ]);
      }
    }
  }, [treatmentDetails]);

  const setComponentVisibility = (requirementDescription) => {

    if (
      treatmentDetails.description === "Aligners" &&
      requirementDescription === "Are you using the GRAPHY workflow?"
    ) {
      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Are you using the GRAPHY workflow?"
      );

      if (req !== undefined) {
        if (req.value === "Yes") {
          let newArr = isComponentVisible.map((item, i) => {
            if (
              item.description === "Are you fabricating In-house?" ||
              item.description ===
                "Does your patient have any special considerations?"
            ) {
              return {
                ...item,
                visible: true,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);
        } else {
          let newArr = isComponentVisible.map((item, i) => {
            if (
              item.description === "Are you fabricating In-house?" ||
              item.description ===
                "Does your patient have any special considerations?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);
        }
      }
    } else if (
      treatmentDetails.description === "Evident Smile Package" &&
      requirementDescription === "Gingival Contouring"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let gingivalReq = treatmentDetails.requirements.find(
        (x) => x.description === "Gingival Contouring"
      );

      if (gingivalReq !== undefined) {
        if (gingivalReq.needGingivalGuide === true) {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Measurement") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: true,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);
        } else {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Measurement") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: true,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);
          handleRequirementParentSelectValue("Measurement", null, []);
        }
      }
    } else if (
      treatmentDetails.description === "Surgical Planning and Guide" &&
      requirementDescription === "Is this a full arch design?"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let archDesignReq = treatmentDetails.requirements.find(
        (x) => x.description === "Is this a full arch design?"
      );

      if (archDesignReq !== undefined) {
        if (archDesignReq.value === "Yes") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Arch Design") {
              return {
                ...item,
                visible: true,
              };
            } else if (
              item.description === "Does this include a restorative design?"
            ) {
              return {
                ...item,
                visible: true,
              };
            } else if (
              item.description === "Do you want to add a Stackable Guide?"
            ) {
              return {
                ...item,
                visible: true,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
          treatmentDetailsCopy.toothNumbers = [];

          setTreatmentDetails(treatmentDetailsCopy);

          if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
          }
        } else if (archDesignReq.value === "No") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Arch Design") {
              return {
                ...item,
                visible: false,
              };
            } else if (
              item.description === "Does this include a restorative design?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else if (
              item.description === "Do you want to add a Stackable Guide?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          handleRequirementParentSelectValue("Arch Design", null, []);
          handleRequirementParentSelectValue(
            "Does this include a restorative design?",
            null,
            []
          );
          handleRequirementParentSelectValue(
            "Do you want to add a Stackable Guide?",
            null,
            []
          );
        } else {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Arch Design") {
              return {
                ...item,
                visible: false,
              };
            } else if (
              item.description === "Does this include a restorative design?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else if (
              item.description === "Do you want to add a Stackable Guide?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);
        }
      }
    } else if (
      treatmentDetails.description === "Models" &&
      requirementDescription === "Type of Model"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Type of Model"
      );

      if (req !== undefined) {
        if (req.value === "Base Model") {
          let newArr = isComponentVisible.map((item, i) => {
            if (
              item.description === "Alignment On Semi-Adjustable Articulator"
            ) {
              return {
                ...item,
                visible: true,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
          treatmentDetailsCopy.toothNumbers = [];

          setTreatmentDetails(treatmentDetailsCopy);

          if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
          }
        } else if (
          req.value === "Implant Model" ||
          req.value === "Ortho Model"
        ) {
          let newArr = isComponentVisible.map((item, i) => {
            if (
              item.description === "Alignment On Semi-Adjustable Articulator" ||
              item.description === "Articulator"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          handleRequirementParentSelectValue(
            "Alignment On Semi-Adjustable Articulator",
            null,
            []
          );
          handleRequirementParentSelectValue("Articulator", null, []);
        }
      }
    } else if (
      treatmentDetails.description === "Models" &&
      requirementDescription === "Alignment On Semi-Adjustable Articulator"
    ) {
      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Alignment On Semi-Adjustable Articulator"
      );

      if (req !== undefined) {
        if (req.value === "Yes") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Articulator") {
              return {
                ...item,
                visible: true,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
          treatmentDetailsCopy.toothNumbers = [];

          setTreatmentDetails(treatmentDetailsCopy);

          if (handleTreatmentUpdate) {
            handleTreatmentUpdate(treatmentDetailsCopy);
          }
        } else {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Articulator") {
              return {
                ...item,
                visible: false,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          handleRequirementParentSelectValue("Articulator", null, []);
        }
      }
    } else if (
      treatmentDetails.description === "Digital Partial Dentures" &&
      requirementDescription ===
        "Cast Partial Framework or Acrylic Partial Denture"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let req = treatmentDetails.requirements.find(
        (x) =>
          x.description === "Cast Partial Framework or Acrylic Partial Denture"
      );

      if (req !== undefined) {
        if (req.value === "Cast Partial Framework") {
          let visibleRequirements = [
            "Tooth Arch",
            "Type of Framework",
            "Manufacturing Material",
            "Design Teeth For Partial Rests",
            "Mesial Occlusal Tooth #",
            "Distal Occlusal Tooth #",
            "Cingulum Tooth #",
            "Ball Tooth #",
            "Circumferential Tooth #",
            "I-Bar Tooth #",
            "T-Bar Tooth #",
            "RPA Tooth #",
            "RPI Tooth #",
            "Ring Tooth #",
            "Wrought Wire Tooth #",
            "Flexible Combo Tooth #",
            "Provide Drawing Instructions (Optional)",
          ];

          let hiddenRequirements = [
            "Library To Be Used",
            "Teeth Segmentation",
            "Denture Output",
            "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
            "Support Bar",
            "Where To Place Clasps",
          ];

          let newArr = isComponentVisible.map((item, i) => {
            let isVisible =
              visibleRequirements.find((r) => r === item.description) ===
              item.description;

            if (isVisible === true) {
              return {
                ...item,
                visible: true,
              };
            } else if (isVisible === false) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
                visible: false,
              };
            }
          });
          setIsComponentVisible(newArr);

          hiddenRequirements.forEach((h) => {
            handleRequirementParentSelectValue(h, null, []);
          });
        } else if (req.value === "Acrylic Partial Denture") {
          let acrylicVisibleRequirements = [
            "Library To Be Used",
            "Teeth Segmentation",
            "Denture Output",
            "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
            "Support Bar",
            "Where To Place Clasps",
          ];

          let acrylicHiddenRequirements = [
            "Tooth Arch",
            "Type of Framework",
            "Manufacturing Material",
            "Design Teeth For Partial Rests",
            "Mesial Occlusal Tooth #",
            "Distal Occlusal Tooth #",
            "Cingulum Tooth #",
            "Ball Tooth #",
            "Circumferential Tooth #",
            "I-Bar Tooth #",
            "T-Bar Tooth #",
            "RPA Tooth #",
            "RPI Tooth #",
            "Ring Tooth #",
            "Wrought Wire Tooth #",
            "Flexible Combo Tooth #",
            "Provide Drawing Instructions (Optional)",
          ];
          let newArr = isComponentVisible.map((item, i) => {
            let isVisible =
              acrylicVisibleRequirements.find((r) => r === item.description) ===
              item.description;
            if (isVisible === true) {
              return {
                ...item,
                visible: true,
              };
            } else if (isVisible === false) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return item;
            }
          });
          setIsComponentVisible(newArr);

          acrylicHiddenRequirements.forEach((h) => {
            handleRequirementParentSelectValue(h, null, []);
          });
        }
      }
    } else if (
      treatmentDetails.description === "Photographic Case Presentation" &&
      requirementDescription === "Which Presentation do you want?"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Which Presentation do you want?"
      );
      if (req !== undefined) {

        let hasVirtualSmile =
          req.value.find((r) => r === "Virtual Smile Mock up with Outline") ===
          "Virtual Smile Mock up with Outline";
        let hasdfa =
          req.value.find((r) => r === "Dentofacial Analysis (DFA)") ===
          "Dentofacial Analysis (DFA)";

        if (hasdfa) {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Platform Height") {
              return {
                ...item,
                visible: true,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        } else if (!hasdfa) {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Platform Height") {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        }

        if (hasVirtualSmile) {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Max Incisal Edge Position") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Gingival Levels") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Tooth Shade") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Tooth Shape") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: true,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
          handleRequirementSelectValue("Platform Height", null, []);
        } else if (!hasVirtualSmile) {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Max Incisal Edge Position") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Gingival Levels") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Tooth Shade") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Tooth Shape") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Tooth Chart") {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
          handleRequirementSelectValue("Max Incisal Edge Position", null, []);
          handleRequirementSelectValue("Gingival Levels", null, []);
          handleRequirementParentSelectValue("Tooth Shade", null, []);
          handleRequirementParentSelectValue("Tooth Shape", null, []);
        }

        let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
        treatmentDetailsCopy.toothNumbers = [];

        setTreatmentDetails(treatmentDetailsCopy);

        if (handleTreatmentUpdate) {
          handleTreatmentUpdate(treatmentDetailsCopy);
        }
        // handleRequirementSelectValue()
      }
    } else if (
      treatmentDetails.description === "Implant Crowns" &&
      requirementDescription === "Type of Implant Restoration"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Type of Implant Restoration"
      );

      if (req !== undefined) {
        setIsComponentVisible([
          {
            description: "Collar Height Buccal",
            visible: true,
          },
          {
            description: "Collar Height Lingual",
            visible: true,
          },
          {
            description: "Scanbody",
            visible: true,
          },
          {
            description: "Kind of Implant",
            visible: true,
          },
          {
            description: "Emergence Profile",
            visible: true,
          },
          {
            description: "Tissue Pressure",
            visible: true,
          },
        ]);
        if (req.value === "Crown with Access Hole (Crown Only)") {
          let hiddenReqs = [
            "Collar Height Buccal",
            "Collar Height Lingual",
            "Scanbody",
            "Kind of Implant",
            "Emergence Profile",
            "Tissue Pressure",
          ];

          let newArr = isComponentVisible.map((item, i) => {
            let isHidden =
              hiddenReqs.find((r) => r === item.description) ===
              item.description;

            if (isHidden === true) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);

          handleRequirementParentSelectValue("Collar Height Buccal", "", []);
          handleRequirementParentSelectValue("Collar Height Lingual", "", []);
          handleRequirementParentSelectValue("Scanbody", "", []);
          handleRequirementParentSelectValue("Kind of Implant", "", []);
          handleRequirementParentSelectValue("Emergence Profile", "", []);
          handleRequirementParentSelectValue("Tissue Pressure", "", []);
        } else if (
          req.value === "Screw-Retained Crown on Ti-Base" ||
          req.value === "Screw-Retained Crown (Direct to Fixture)"
        ) {
          let hiddenReqs = [
            "Collar Height Buccal",
            "Collar Height Lingual",
            "Emergence Profile",
            "Tissue Pressure",
          ];
          let newArr = isComponentVisible.map((item, i) => {
            let isHidden =
              hiddenReqs.find((r) => r === item.description) ===
              item.description;

            if (isHidden === true) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        } else {
          let hiddenReqs = [
            "Collar Height Buccal",
            "Collar Height Lingual",
            "Scanbody",
            "Kind of Implant",
            "Emergence Profile",
            "Tissue Pressure",
          ];
          let newArr = isComponentVisible.map((item, i) => {
            let isHidden =
              hiddenReqs.find((r) => r === item.description) ===
              item.description;

            if (isHidden === true) {
              return {
                ...item,
                visible: true,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);

          // handleRequirementParentSelectValue("On Ti Base", null, []);
        }
      }
    } 
    else if (
      treatmentDetails.description === "Digital Complete Dentures" && requirementDescription === "Are you fabricating In-house ?")
      {  
        let isTruedent = treatmentDetails.requirements.some(
          (x) => x.value === "Truedent Denture" && x.description ==="Type of Complete Dentures"
        );

        let isNotfabricatingInHouse = treatmentDetails.requirements.some(
          (x) => x.value === "No" && x.description ==="Are you fabricating In-house ?"
        ); 
         
            if(isTruedent && isNotfabricatingInHouse)
            {
              let isLabArtisan = treatmentDetails.requirements.some(
                (x) => x.lab === "Artisan Dental Laboratory" && x.description ==="Are you fabricating In-house ?"
              );

              setIsSelectedArtisan(isLabArtisan);

              let newArr = isComponentVisible.map((item, i) => {
                if (
                  item.description === "Type of Complete Dentures" || 
				          item.description === "Tooth Mould/Shape Anterior" ||
                  item.description === "Tooth Mould/Shape Posterior" ||
                  item.description === "Papillameter High Lip Line" ||
                  item.description === "Papillameter Rest" ||
                  item.description === "Vertical Dimension of Occlusion" ||
                  item.description === "Ridge Relationship" ||
                  item.description === "Workflow" ||
                  item.description === "Occlusal Scheme" ||
                  item.description === "Reference" ||
                  item.description === "Tooth Color" ||
                  item.description === "Gingival Shades" ||
                  item.description === "Are you fabricating In-house ?" ||
                  item.description === "RX Notes"
                ) {
                  return {
                    ...item,
                    visible: true,
                  };
                } else {
                  return { ...item, visible: false };
                }
              });
    
              setIsComponentVisible(newArr);
            }
            else if(isTruedent)
            {
              
              setIsSelectedArtisan(false);
              setTruedentModalOption(1);
              setOpenTrudentInfoModal(true);
              let newArr = isComponentVisible.map((item, i) => {
                if (
                  item.description === "Tooth Mould/Shape Anterior" ||
                  item.description === "Tooth Mould/Shape Posterior" ||
                  item.description === "Papillameter High Lip Line" ||
                  item.description === "Papillameter Rest" ||
                  item.description === "Vertical Dimension of Occlusion" ||
                  item.description === "Ridge Relationship" ||
                  item.description === "Type of Complete Dentures" || 
                  item.description === "Workflow" ||
                  item.description === "Occlusal Scheme" ||
                  item.description === "Reference" || 
                  item.description === "Are you fabricating In-house ?" ||
                  item.description === "RX Notes"
                ) 
                {
                  return {
                    ...item,
                    visible: true,
                  };
                } else {
                  return { ...item, visible: false };
                }
              });

              setIsComponentVisible(newArr);

            }

      }
      
      else if (
      treatmentDetails.description === "Digital Complete Dentures" &&
      requirementDescription === "Type of Complete Dentures"
    ) {   
      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Type of Complete Dentures"
      );

      if (req !== undefined) {
        if (req.value === "Copy Dentures") {
         
          let newArr = isComponentVisible.map((item, i) => {
            if (
              item.description === "Type of Complete Dentures" ||
              item.description === "Default Desired Output Type/s" ||
              item.description === "Default Teeth Segmentation" ||
              item.description === "Are you fabricating In-house?" ||
              item.description === "RX Notes"
            ) {
              return {
                ...item,
                visible: true,
              };
            } else {
              return { ...item, visible: false };
            }
          });

          setIsComponentVisible(newArr);
        } else {
          if (process.env.REACT_APP_EVIDENT_ENABLETRUEDENT_NEWFORM === "TRUE") 
          {
             
            if (req.value === "Truedent Denture") 
            {
              setOpenTrudentInfoModal(true);              
              setTruedentModalOption(0);
             
              let newArr = isComponentVisible.map((item, i) => {
                if (
                  item.description === "Type of Complete Dentures" || 
                  item.description === "Workflow" ||
                  item.description === "Occlusal Scheme" ||
                  item.description === "Reference" ||
                  item.description === "Are you fabricating In-house ?" ||
                  item.description === "Tooth Mould/Shape Anterior" ||
                  item.description === "Tooth Mould/Shape Posterior" ||
                  item.description === "Papillameter High Lip Line" ||
                  item.description === "Papillameter Rest" ||
                  item.description === "Vertical Dimension of Occlusion" ||
                  item.description === "Ridge Relationship" ||
                  item.description === "RX Notes"
                ) 
                {
                  return {
                    ...item,
                    visible: true,
                  };
                } else {
                  return { ...item, visible: false };
                }
              });

              setIsComponentVisible(newArr);
            } // for Full Denture
            else 
            { 
              let b = "c";
              let newArr = isComponentVisible.map((item, i) => { 
                if (
                  item.description == "Maxillary/Mandibular/Bite Scan Sent" ||
                  item.description == "Workflow" ||
                  item.description == "Occlusal Scheme" ||
                  item.description == "Reference" ||
                  item.description == "Ridge Relationship" ||
                  item.description == "Vertical Dimension of Occlusion" ||
                  item.description == "Tooth Color" ||
                  item.description == "Gingival Shades" ||
                  item.description == "Tooth Mould/Shape Anterior" ||
                  item.description == "Tooth Mould/Shape Posterior" ||
                  item.description == "Papillameter High Lip Line" ||
                  item.description === "Are you fabricating In-house ?" ||
                  item.description == "Papillameter Rest"
                ) { 
                  return { ...item, visible: false };
                  
                } else { 
                  return {
                    ...item,
                    visible: true,
                  };
                }
              });
              setIsComponentVisible(newArr);

              if (req.value === "Jimmy Stegall Dentures") {
                setOpenJimmyStegallDenturesModal(true);
              }
            }
          } 
          else 
          {
            let b = 'c';
            let newArr = isComponentVisible.map((item, i) => {

                return {
                    ...item,visible:true,
                };

            });
            setIsComponentVisible(newArr); 

          }
        }
      } else {
        let newArr = isComponentVisible.map((item, i) => {
          return {
            ...item,
          };
        });
        setIsComponentVisible(newArr);
      }
    } else if (
      treatmentDetails.description === "Devices" &&
      requirementDescription === "Which Protocol do you want?"
    ) {
      // let newArr = isComponentVisible.map((item, i) => {
      //   return {
      //     ...item,
      //     visible: false,
      //   };
      // });
      // setIsComponentVisible(newArr);

      let req = treatmentDetails.requirements.find(
        (x) => x.description === "Which Protocol do you want?"
      );

      if (req !== undefined) {
        if (req.value === "Kois Deprogrammer") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Deprogrammer Options") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Nightguard Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Mandibular Anterior Jig Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Orthopedic Appliance Options") {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        } else if (req.value === "Kois Nightguard") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Deprogrammer Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Nightguard Options") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Mandibular Anterior Jig Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Orthopedic Appliance Options") {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        } else if (req.value === "Mandibular Anterior Jig") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Deprogrammer Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Nightguard Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Mandibular Anterior Jig Options") {
              return {
                ...item,
                visible: true,
              };
            } else if (item.description === "Orthopedic Appliance Options") {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        } else if (req.value === "Orthopedic Appliance") {
          let newArr = isComponentVisible.map((item, i) => {
            if (item.description === "Deprogrammer Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Nightguard Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Mandibular Anterior Jig Options") {
              return {
                ...item,
                visible: false,
              };
            } else if (item.description === "Orthopedic Appliance Options") {
              return {
                ...item,
                visible: true,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          setIsComponentVisible(newArr);
        }
      
      
    }  
    
  }
  else if (treatmentDetails.description === "CEREC Crown & Bridge" &&  requirementDescription === "Will you be uploading a CEREC .dxd file?" ) 
  { 
    
    let isUploadDxdFile = treatmentDetails.requirements.some(
      (x) => x.value === "Yes" && x.description ==="Will you be uploading a CEREC .dxd file?"
    );
     
    let newArr = isComponentVisible.map((item, i) => {
      if (item.description === "Tooth Chart" || 
          item.description === "Radial Spacer"|| 
          item.description === "Occlusal Spacer"|| 
          item.description === "Proximal Contact Strength"|| 
          item.description === "Occlusal Contact Strength"|| 
          item.description === "Dynamic Contact Strength"|| 
          item.description === "Minimal Radial Thickness"|| 
          item.description === "Minimal Occlusal Thickness"|| 
          item.description === "Margin Thickness"|| 
          item.description === "Width of Ramp"|| 
          item.description === "Angle of Ramp" ) 
      {
        return {
          ...item,
          visible: !isUploadDxdFile,
        };
      } 
      else {
        return { ...item, visible: isUploadDxdFile };
      }
    });

    setIsComponentVisible(newArr); 
  }  
  };


  const updateNotesHandler = (teeth,notes) => {
    setIsUpdatingNotes(prev => !prev);
    const treatmentDetailsCopy = treatmentDetails;

    treatmentDetailsCopy.toothNumbers.forEach(t=>{
      if(teeth.includes(t.tooth)){
        t.notes = notes;
      }
    });

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  }

  const handleSelectTeeth = (teeth) => { 
    const toothNumbers = [...treatmentDetails.toothNumbers];
    segment.trigger({
      event: 'hub_add_selected',
      properties: {
        product_name: treatmentDetails.description,
        product_category: treatmentDetails.treatmentCategoryDescription
      }
    });
    teeth.forEach((newTooth) => {
      const oldTooth = toothNumbers.find(
        (selectedTooth) => selectedTooth.tooth === newTooth.tooth
      );
      if (oldTooth) {
        // replace old selected tooth to new selected tooth
        Object.assign(oldTooth, newTooth);
      } else {
        toothNumbers.push(newTooth);
      }
    });

    const treatmentDetailsCopy = { ...treatmentDetails, toothNumbers };
    setTreatmentDetails(treatmentDetailsCopy);
    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleClearTeeth = (selectedToothNumbers) => {
    const treatmentDetailsCopy = treatmentDetails;
    const teeth = [...treatmentDetails.toothNumbers];
    selectedToothNumbers.forEach((t) =>
      ToothService.removeSelectedTooth(t, treatment.id)
    );
    const filteredToothNumbers = treatmentDetails.toothNumbers.filter(
        tooth => !selectedToothNumbers.includes(tooth.tooth)
    );
    const newTreatmentDetails = {...treatmentDetailsCopy, toothNumbers: filteredToothNumbers};
    setTreatmentDetails(newTreatmentDetails);
    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(newTreatmentDetails);
    }
  };

  const removeTooth = (number) => {
    number = parseInt(number);
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    treatmentDetailsCopy.toothNumbers =
      treatmentDetailsCopy.toothNumbers.filter((x) => x.tooth !== number);

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
    // ToothService.removeSelectedTooth(number, treatment.id);
  };

  const handleRemoveTeeth = (number) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
    treatmentDetailsCopy.toothNumbers =
      treatmentDetailsCopy.toothNumbers.filter((x) => x.tooth !== number);

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleRequirementSelectValue = (
    reqDesc,
    value,
    measurementToZero = false
  ) => {
 
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );
    
    if (typeof getRequirement !== "undefined") {
      getRequirement.value = value;

      if (measurementToZero) {
        if (typeof getRequirement.measurement !== "undefined") {
          getRequirement.measurement = -1;
        }
      }
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleRequirementParentSelectValue = (reqDesc, value, itemList) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
    
    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      getRequirement.value = value;

      let otherRequirement = itemList.filter(
        (x) => parseInt(x.item) !== parseInt(value)
      );

      otherRequirement.forEach((il) => {
        if (il.child !== undefined) {
          il.child.forEach((c) => {
            getRequirement[c.name] = "";
          });
        }
      });
    }

    setTreatmentDetails(treatmentDetailsCopy); 

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
    setComponentVisibility(reqDesc);
  };

  const handleRequirementChildSelectValue = (reqDesc, childName, value) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
    
    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      getRequirement[childName] = value;
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }

    setComponentVisibility(reqDesc);
  };

  const handleAddToOthers = (
    value,
    reqDesc,
    valueWithTextbox,
    specifyString
  ) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );
    let valueIndex = getRequirement.value.indexOf(valueWithTextbox);

    getRequirement.value.forEach((element, index) => {
      if (element.search(specifyString) != -1) {
        getRequirement.value.splice(index, 1);
      }
    });
    getRequirement.value.forEach((element, index) => {
      if (element.search(valueWithTextbox) != -1) {
        getRequirement.value.splice(index, 1);
        getRequirement.value.push(valueWithTextbox);
        getRequirement.value.push(specifyString + value);
      }
    });
  };

  const handleRequirementsSelectList = (reqDesc, value, add, id) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      if (add === false) {
        getRequirement.value = getRequirement.value.filter((x) => x !== value);

        if (id !== undefined) {
          getRequirement.valueid = getRequirement.valueid.filter(
            (x) => x !== id
          );
        }
      } else {
        if (
          reqDesc === "Select up to 4 files to be included in the package" &&
          getRequirement.value.length === 4
        ) {
          alert("Can select only up to 4 files");
          return;
        }

        if (getRequirement.value === null) {
          getRequirement.value = [];
        }
        getRequirement.value.push(value);
        if (id !== undefined) {
          getRequirement.valueid.push(id);
        }
      }
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }

    setComponentVisibility(reqDesc);
  };

  const handleChangeAdditionalNotes = (value) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);
    
    treatmentDetailsCopy.additionalNotes = value;

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  const handleRequirementSelectNeedGingival = (
    reqDesc,
    value,
    measurementToZero = false
  ) => {
    let treatmentDetailsCopy = Object.assign({}, treatmentDetails);

    let getRequirement = treatmentDetailsCopy.requirements.find(
      (x) => x.description === reqDesc
    );

    if (typeof getRequirement !== "undefined") {
      getRequirement.needGingivalGuide = value;

      if (measurementToZero) {
        if (typeof getRequirement.measurement !== "undefined") {
          getRequirement.measurement = -1;
        }
      }
    }

    setTreatmentDetails(treatmentDetailsCopy);

    if (handleTreatmentUpdate) {
      handleTreatmentUpdate(treatmentDetailsCopy);
    }
  };

  var requirementsCount = 1; 






  return (
    <div className="treatment-requirements">
      {treatment.requirements
        .filter((x) => x.description !== "Type of Restoration (Multiple)")
        .map((req, idx) => (
          <div>
            {/* <Box sx={{ marginTop: "45px", marginBottom: "45px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#5B5B5B",
                    color: "#fff",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  {idx + 1}
                </Box>
                <Box
                  sx={{
                    fontSize: "21px",
                    fontWeight: "500",
                    lineHeight: "31px",
                    color: "#27374F",
                  }}
                >
                  {req.description}
                </Box>
              </Box>
            </Box> */}

            {req.description === "Is this a full arch design?" ? (
              <Box sx={{ marginBottom: "45px" }}>
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <ListWithChildComponent
                  
                  req={req}
                  handleRequirementParentSelectValue={
                    handleRequirementParentSelectValue
                  }
                  handleRequirementChildSelectValue={
                    handleRequirementChildSelectValue
                  }
                />
              </Box>
            ) : (
              ""
            )}

            {req.description === "Arch Design" ? (
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ListWithChildComponent
                    req={req}
                    handleRequirementParentSelectValue={
                      handleRequirementParentSelectValue
                    }
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Does this include a restorative design?" ? (
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ListWithChildComponent
                    req={req}
                    handleRequirementParentSelectValue={
                      handleRequirementParentSelectValue
                    }
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Do you want to add a Stackable Guide?" ? (
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ListWithChildComponent
                    req={req}
                    handleRequirementParentSelectValue={
                      handleRequirementParentSelectValue
                    }
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Alignment On Semi-Adjustable Articulator" ? (
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ListWithChildComponent
                    req={req}
                    handleRequirementParentSelectValue={
                      handleRequirementParentSelectValue
                    }
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Tooth Chart" ? (
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ToothChartComponent
                    treatment={treatment}
                    toothChartId={treatment.id}
                    selectedTeeth={treatmentDetails.toothNumbers}
                    notion={notion}
                    onSelectTeeth={handleSelectTeeth}
                    onClearTeeth={handleClearTeeth}
                    onRemoveSelectedTooth={removeTooth}
                    updateNotesHandler={updateNotesHandler}
                    isUpdatingNotes={isUpdatingNotes}
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}
         
            {req.componentType === "ListWithOtherSpecifyComponent" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ListWithOtherSpecifyComponent
                    isCancelled={isCancelled}
                    req={req}
                    handleRequirementSelectValue={handleRequirementSelectValue}
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}
        
            {req.componentType === "ListWithChildComponent" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                    requirement={req}
                    treatment={treatmentDetails.description}
                  />
                  <ListWithChildComponent
                    isCancelled={isCancelled}
                    req={req}
                    handleRequirementParentSelectValue={
                      handleRequirementParentSelectValue
                    }
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                    patientId={patientId}
                    personnelId={personnelId}
                    additionalOrderDetails={additionalOrderDetails}
                    isAllOnXPackage={treatment.isPackage}
                  />

                  {req.description === "Are you fabricating In-house?" &&
                  req.value === "Yes" &&
                  req.nest === true &&
                  treatmentDetails.description === "Aligners" ? (
                    <Typography color="black" marginTop="20px">
                      {" "}
                      &nbsp; I'm fully aware of the correct fabrication process
                      for direct printed aligners
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.componentType === "SelectListComponent" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <SelectListComponent
                    req={req}
                    handleRequirementParentSelectValue={
                      handleRequirementParentSelectValue
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Gingiva Esthetics / Festooning" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ImagesListComponent
                    req={req}
                    itemList={festooning}
                    handleRequirementSelectValue={handleRequirementSelectValue}
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Shade (Case Presentation PDF Purposes)" ? (
              <Box sx={{ marginBottom: "45px" }}>
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <ImagesListComponent
                  req={req}
                  itemList={shades}
                  handleRequirementSelectValue={handleRequirementSelectValue}
                />
              </Box>
            ) : (
              ""
            )}

            {req.description ===
            "Select up to 4 files to be included in the package" ? (
              <Box sx={{ marginBottom: "45px" }}>
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <FilesNeededForSmilePackageComponent
                  req={req}
                  handleRequirementsSelectList={handleRequirementsSelectList}
                />
              </Box>
            ) : (
              ""
            )}

            {(req.componentType === "CheckboxListComponent") &
            (req.description !==
              "Does your patient have any special considerations?") ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <CheckboxListComponent
                    req={req}
                    handleRequirementsSelectList={handleRequirementsSelectList}
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Smile Type" ? (
              <Box sx={{ marginBottom: "45px" }}>
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <ImagesListComponent
                  req={req}
                  itemList={toothStyles}
                  handleRequirementSelectValue={handleRequirementSelectValue}
                />
              </Box>
            ) : (
              ""
            )}

            {/*//new component*/}

            {req.description ===
            "Does your patient have any special considerations?" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <CheckboxListComponent
                    req={req}
                    handleRequirementsSelectList={handleRequirementsSelectList}
                  />
                  {req.value.includes("Any other considerations") ? (
                    <Box>
                      <TextField
                        name="Considerations"
                        placeholder="Please specify here:"
                        sx={{
                          backgroundColor: "#fff",
                          width: "400px",
                        }}
                        onBlur={(e) =>
                          handleAddToOthers(
                            e.target.value,
                            req.description,
                            "Any other considerations",
                            "please specify: "
                          )
                        }
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Type of Implant Restoration" ? (
              <Box sx={{ marginBottom: "45px" }}>
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <ImagesListComponent
                  larger
                  returnItemList
                  req={req}
                  itemList={crownOrAbutment}
                  handleRequirementSelectValue={
                    handleRequirementParentSelectValue
                  }
                />
              </Box>
            ) : (
              ""
            )}

            {req.description === "Emergence Profile" &&
            isComponentVisible.find((x) => x.description === req.description)
              .visible ? (
              <Box sx={{ marginBottom: "45px" }}>
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <ImagesListComponent
                  larger
                  returnItemList
                  req={req}
                  itemList={emergenceProfile}
                  handleRequirementSelectValue={
                    handleRequirementParentSelectValue
                  }
                />
              </Box>
            ) : (
              ""
            )}

            {/* {req.description === "Will this be on Ti-Base?" &&
            isComponentVisible.find((x) => x.description === req.description)
              .visible ? (
              <Box sx={{ marginBottom: "45px" }}>
                {console.log(req)}
                <RequirementBoxCount
                  count={requirementsCount++}
                  description={req.description}
                />
                <ImagesListComponent
                  larger
                  returnItemList
                  onTiBase
                  req={req}
                  itemList={onTiBaseValues()}
                  handleRequirementSelectValue={
                    handleRequirementParentSelectValue
                  }
                />
              </Box>
            ) : null} */}

            {req.componentType === "Textarea" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <TextField
                    multiline
                    rows={2}
                    placeholder={req?.itemList?.placeholder}
                    InputProps={{
                      style: { width: "100%", alignItems: "start" },
                    }}
                    sx={{ width: "100%", background: "#fff", marginTop: "5px" }}
                    value={req.value}
                    onChange={(e) =>
                      handleRequirementSelectValue(
                        req.description,
                        e.target.value,
                        []
                      )
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Tooth Shade" &&
              isComponentVisible.find((x) => x.description === req.description)
                ?.visible && (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ImagesListComponent
                    returnItemList
                    req={req}
                    itemList={smileShade}
                    handleRequirementSelectValue={handleRequirementSelectValue}
                    smileMockup
                  />
                </Box>
              )}

            {req.description === "Tooth Shape" &&
              isComponentVisible.find((x) => x.description === req.description)
                ?.visible && (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <ImagesListComponent
                    returnItemList
                    req={req}
                    itemList={smileShape}
                    handleRequirementSelectValue={handleRequirementSelectValue}
                    smileMockup
                  />
                </Box>
              )}

            {req.componentType === "text" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <TextField
                    placeholder={req.description}
                    InputProps={{
                      style: { width: "100%", alignItems: "start" },
                    }}
                    sx={{ width: "50%", background: "#fff", marginTop: "5px" }}
                    value={req.value}
                    onChange={(e) =>
                      handleRequirementSelectValue(
                        req.description,
                        e.target.value,
                        []
                      )
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )} 
            {req.componentType === "number" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <TextField
                    size={"medium"}
                    type="number"
                    placeholder={req.description}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {req.description.includes("#") ? "" : (treatment.productId == 1285 ? "µm":"mm") }
                        </InputAdornment>
                      ),
                    }}
                    sx={{ backgroundColor: "#fff", width: "250px" }}
                    value={req.value}
                    onChange={(e) =>
                      handleRequirementSelectValue(
                        req.description,
                        e.target.value,
                        []
                      )
                    }
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Desired Base Thickness" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <DesiredBaseThickness
                    req={req}
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                    handleRequirementsSelectList={handleRequirementsSelectList}
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {req.description === "Desired Overjet & Overbite" ? (
              isComponentVisible.find(
                (x) => x.description === req.description
              ) === undefined ||
              isComponentVisible.find((x) => x.description === req.description)
                .visible ? (
                <Box sx={{ marginBottom: "45px" }}>
                  <RequirementBoxCount
                    count={requirementsCount++}
                    description={req.description}
                  />
                  <DesiredBaseThickness
                    req={req}
                    handleRequirementChildSelectValue={
                      handleRequirementChildSelectValue
                    }
                    handleRequirementsSelectList={handleRequirementsSelectList}
                    isTick={true}
                  />
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )} 

          {req.description === "Papillameter Rest" ? ( 
             isComponentVisible.find(
               (x) => x.description === req.description
             ) === undefined ||
             isComponentVisible.find((x) => x.description === req.description)
               .visible ? (
               <Box sx={{ marginBottom: "45px" }}>
                 <RequirementBoxCount
                   count={requirementsCount++}
                   description={req.description}
                 />
                 <TextField
                   size={"medium"}
                   type="number"
                   placeholder={req.description}
                   InputProps={{
                     endAdornment: (
                       <InputAdornment position="end">
                         {"mm"}
                       </InputAdornment>
                     ),
                   }}
                   sx={{ backgroundColor: "#fff", width: "250px" }}
                   value={req.value}
                   onChange={(e) =>
                     handleRequirementSelectValue(
                       req.description,
                       e.target.value,
                       []
                     )
                   }
                 />
               </Box>
             ) : (
               ""
             )
           ) : (
             ""
           )}

        {req.description === "Papillameter High Lip Line" ? ( 
             isComponentVisible.find(
               (x) => x.description === req.description
             ) === undefined ||
             isComponentVisible.find((x) => x.description === req.description)
               .visible ? (
               <Box sx={{ marginBottom: "45px" }}>
                 <RequirementBoxCount
                   count={requirementsCount++}
                   description={req.description}
                 />
                 <TextField
                   size={"medium"}
                   type="number"
                   placeholder={req.description}
                   InputProps={{
                     endAdornment: (
                       <InputAdornment position="end">
                         {"mm"}
                       </InputAdornment>
                     ),
                   }}
                   sx={{ backgroundColor: "#fff", width: "250px" }}
                   value={req.value}
                   onChange={(e) =>
                     handleRequirementSelectValue(
                       req.description,
                       e.target.value,
                       []
                     )
                   }
                 />
               </Box>
             ) : (
               ""
             )
           ) : (
             ""
           )}

          </div>
        ))}
      {/* <Divider sx={{marginTop:'60px', marginBottom: '60px'}}/> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#5B5B5B",
              color: "#fff",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
            }}
          >
            {requirementsCount++}
            {/* {treatmentDetails.requirements.find((it) =>
              [it.description]?.includes("Type of Restoration (Multiple)")
            )
              ? treatmentDetails.requirements.length + 1
              : treatmentDetails.requirements.length + 2} */}
          </Box>
          <Box
            sx={{
              fontSize: "21px",
              fontWeight: "500",
              lineHeight: "31px",
              color: "#27374F",
            }}
          >
            RX Notes
          </Box>
        </Box>

        <Box>
          <TextField
            multiline
            sx={{
              width: "100%",
              background: "#fff",
              marginTop: "5px",
              overFlow: "auto",
            }}
            rows={12}
            value={treatmentDetails.additionalNotes}
            onChange={(e) => handleChangeAdditionalNotes(e.target.value)}
          />
        </Box>
      <TruedentInfoModal isOpen={openTrudentInfoModal} handleClose={handCloseTrudentInfo} Option={truedentModalOption} selectArtisan={handleSelectArtisan} /> 
      <ArtisanInfoModal isOpen={isSelectedArtisan} handleClose={handCloseArtisanInfo} /> 
      <JimmyStegallDenturesModal isOpen={openJimmyStegallDenturesModal} handleClose={handCloseJimmyStegallDenturesModal} />
      </Box>
    </div>
  );
}

export default TreatmentForms;
