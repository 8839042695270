import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import ProductUpsellCarousel from "../productUpsellCarousel/productUpsellCarousel";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import OrderSuccessMessage from "./orderDetails/orderSuccessMessage";
import OrderDetails from "./orderDetails/orderDetails";
import { updateUseCurrentScan } from "../../../redux/actions";
import { apiUrl } from "../../../constants";
import { fileNestingProduct, prebookingProduct } from "../../../constants/specialProducts";
function mapStateToProps(state) {
  return { userInfo: state.userInfo };
}

function CaseConfirmationPage({
  selectedTreatment,
  patientDetails,
  confirmationDetails,
  productsToShow,
  onClickAboutTreatment,
  onClickOrderNow
}) {
  const [upsellProducts,setUpsellProducts] = useState([]);
  const [isLoadingUpsellProducts,setIsLoadingUpsellProducts] = useState(true);
  const dispatch = useDispatch();


  const getProductUpsells = async (productIds) => {
    const response = await fetch(
      apiUrl + "product/recommendations?mainTreatmentTypeId=AE1A0430-94B0-4A40-BB9C-A380A82A183E"
    );
    const data = await response.json();

    return data;
  }

  const getUpsellProductsBasedOnTreatments = async () => {
    const selectedTreatmentIds = selectedTreatment.map((product) => {
      if (
        product.id !== process.env.REACT_APP_ALL_ON_X_IMPLANTS_ID ||
        (product.id === process.env.REACT_APP_ALL_ON_X_IMPLANTS_ID &&
          !product.isPackage)
      ) {
        return product.id;
      }
    });
    const selectedTreatmentIdsString = selectedTreatmentIds.join(",");
    const upsellProducts = await getProductUpsells(selectedTreatmentIdsString);

    // only allow prebooking as upsell product
    const upsellProductsToShow = [];
    upsellProducts.forEach((product) => {
      if (product === "prebooking") {
        upsellProductsToShow.push(prebookingProduct);
      }

      if(product === 'all-on-x-package'){
        const allOnXImplants = productsToShow.filter(product=>product.id === process.env.REACT_APP_ALL_ON_X_IMPLANTS_ID);
        if(allOnXImplants.length){
          const allOnXImplants_0 = allOnXImplants[0];
          allOnXImplants_0.isPackage = true;
          upsellProductsToShow.push(allOnXImplants_0);
        }
      }
    });

    setUpsellProducts(upsellProductsToShow);
    setIsLoadingUpsellProducts(false);
  }

  useEffect(() => {
    dispatch(updateUseCurrentScan(true));
    if(selectedTreatment && productsToShow){
      getUpsellProductsBasedOnTreatments()
    }
  }, []);

  return (
    <Grid2 container marginTop={5}>
      <Grid2 xs={12} lg={4} lgOffset={2}>
        <OrderSuccessMessage />
      </Grid2>
      <Grid2 xs={12} lg={3} >
        <OrderDetails confirmationDetails={confirmationDetails} patientDetails={patientDetails} selectedTreatments={selectedTreatment} />
      </Grid2>
      <Grid2 xs={12} lg={3}></Grid2>

      {isLoadingUpsellProducts && <>
        <Grid2 display="flex" justifyContent="center" item xs={12} marginTop={5} lg={8} lgOffset={2}>
          <CircularProgress color="success" />
        </Grid2>
      </>}
      {!isLoadingUpsellProducts && !!upsellProducts.length && <>
        <Grid2 item xs={12} lg={8} lgOffset={2}>
          <Typography variant="h5" fontWeight="bold" style={{position: 'absolute', marginTop: '15px', marginLeft: '20px'}}>You also may need</Typography>
        </Grid2>
        <Grid2 item xs={12} lg={8} lgOffset={2}>
          <ProductUpsellCarousel patient={patientDetails} onClickOrderNow={onClickOrderNow} onClickAboutTreatment={onClickAboutTreatment} upsellProducts={upsellProducts} selectedTreatment={selectedTreatment}/>        
        </Grid2>
      </>}
    </Grid2>
  );
}

export default connect(mapStateToProps, null)(CaseConfirmationPage);
