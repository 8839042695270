import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Widget, deleteMessages,addResponseMessage, toggleWidget, addUserMessage, renderCustomComponent, getMessages, setMessages } from './chat-widget/index';
import './styles.css';
import AskAiService from '../../services/askAi.service';
import * as ChatWidget from './chat-widget';
import { MESSAGE_SENDER } from './chat-widget/src/constants';
import Message from './chat-widget/src/components/Widget/components/Conversation/components/Messages/components/Message';

//import Message from '../components/Widget/components/Conversation/components/Messages/components/Message';


console.log(ChatWidget);

const NewSessionButton = ({ startNewSession }) => (
  <div
    style={{
      textAlign: 'center',
      padding: '10px',
      backgroundColor: 'none',
    }}
  >
    <button
      onClick={startNewSession}
      style={{
        background: 'none',
        border: 'none',
        backgroundColor: 'none',
        color: '#007BFF',
        cursor: 'pointer',
        fontSize: '14px',
      }}
    >
      Wanna start a new session? Click here!
    </button>
  </div>
);


const AskAiChatWidget = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [sessionId, setSessionId] = useState(null); // Replace with actual session ID
  let chatList = [];

  const getSessionId = async () => {
    let storedSessionId = localStorage.getItem("aiSessionId");
    if (storedSessionId == null || storedSessionId === undefined) {
      // If no sessionId in localStorage, fetch from the database
      const latestSessionFromDb = await AskAiService.getCurrentSession();
      console.log("aiSessionId", latestSessionFromDb);
      localStorage.setItem("aiSessionId", latestSessionFromDb.toString()); // Ensure it's stored as a string
  
      return latestSessionFromDb;
    } else {
      return parseInt(storedSessionId, 10); // Parse as an integer
    }
  };
  
  const startNewSession = () => {
    deleteMessages(); // Clear the chat widget messages
    
    // Increment the sessionId and update localStorage
    setSessionId(prevSessionId => {
      const newSessionId = prevSessionId + 1;
      localStorage.setItem("aiSessionId", newSessionId.toString()); // Store as string
      return newSessionId;
    });
  
    addResponseMessage("Welcome to a new session! Let's start fresh. Now, try to ask me questions 😊"); // Add a default greeting
  };
  


  const toggleChatWindow = () => {
    toggleWidget();
    // deleteMessages(); // Clear messages in the widget
    setIsOpen((prev) => !prev);  // Toggle chat window state
  };

  useEffect(()=> {
    var fetchSession = async() => {
      var session = await getSessionId();
      setSessionId(session);
    }
      fetchSession();
    
  }, [])

  // Get random greeting message
  const getRandomGreeting = (firstName) => {
    const greetings = [
      `Hello ${firstName}! Evie here 😊 How can I assist you today?`,
      `Hey ${firstName}, great to see you! Need a hand with something?`,
      `Hi ${firstName}, I'm Evie! What's on your mind today?`,
      `Hello there ${firstName}! What brings you here? How can I help?`,
      `Hi ${firstName}! I’m here to help however I can. What can I do for you?`,
      `Hey ${firstName}! Evie here. How’s your day going? Anything I can assist with?`,
      `Hi ${firstName}! Lovely to connect. Let me know how I can support you today.`,
      `Hi there, ${firstName}! Evie at your service 😊 What's up?`,
      `Hello ${firstName}! How’s it going? I’m here if you need anything.`,
      `Hey ${firstName}! I’m Evi—happy to help with anything on your mind!`,
      `Hi ${firstName}! I’m here and ready to assist you. What's next?`,
      `Hello ${firstName}! How’s your day shaping up? Let me know how I can help!`,
      `Hey ${firstName}, Evie here. I’m ready whenever you are!`,
      `Hi ${firstName}! Evie reporting for duty 😊 How can I assist you?`,
      `Hello ${firstName}! Need any help today? I’m here for you!`,
      `Hey ${firstName}! Hope your day is going well. What’s up?`,
      `Hi ${firstName}! Evie here, ready to lend a hand. What can I do for you?`,
      `Hello, ${firstName}! It’s Evie. What can I help you with today?`,
      `Hey there ${firstName}! How can I support you today?`,
      `Hi ${firstName}! Evie here. Anything on your mind? What’s up?`
    ];
    return greetings[Math.floor(Math.random() * greetings.length)];
  };

  // Add greeting message to chat
  const addGreetingMessage = async () => {
    const greetingMessage = getRandomGreeting(userInfo.FirstName);
    await AskAiService.addGreetingMessage({
      sessionId,
      isSentByAi: true,
      message: greetingMessage,
      isToShow: true,
    });
  };

  // Handle message submission
  const onSubmitHandler = async (message) => {
    if (!isSubmitting && message) {
      setSubmitting(true);
      const payload = {
        sessionId: sessionId,
        isSentByAi: false,
        message: message,
        isToShow: true
      };

      try {
        // Send user message to Smart Clinic API
        const response = await AskAiService.sendToDatabase(sessionId, message);
        //TODO: Get Chats
        addUserMessage(message, response.id);
        await AskAiService.sendToAi(sessionId, userInfo.FirstName, userInfo.Email);

      } catch (error) {
        console.error("Error during API calls", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  // Handle new user message
  const handleNewUserMessage = (newMessage) => {
    onSubmitHandler(newMessage); // Send the user message and handle AI response
  };

  // useEffect(() => {
  //   let interval;

  //   const fetchData = async () => {
  //     const response = await AskAiService.requestChat(sessionId);

  //     if (response.length === 0) {
  //       setChatList([]); // Clear chat list if no messages exist
  //       deleteMessages(); // Clear widget messages

  //       if (!response.some((chat) => chat.isSentByAi)) {
  //         console.log("no greet");
  //         await addGreetingMessage(); // Add greeting if no AI messages yet
  //       }
  //     } else {
        

  //       const newMessages = [];
  //       response
  //         .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  //         .forEach((msg) => {
  //           const isMessageAlreadyInList = chatListRef.current.some((chat) => chat.id === msg.id);
  //           if (!isMessageAlreadyInList && msg.isToShow) {
  //             newMessages.push({
  //               message: msg.message,
  //               isSentByAi: msg.isSentByAi,
  //               createdAt: msg.createdAt,
  //               isToShow: true,
  //               id: msg.id,
  //             });

  //             // Update the widget only if the message is new
  //             if (msg.isSentByAi) {
  //               addResponseMessage(msg.message);
  //             } else {
  //               addUserMessage(msg.message);
  //             }
  //           }
  //         });

  //       if (newMessages.length > 0) {
  //         setChatList((prevChatList) => [...prevChatList, ...newMessages]);
  //       }
  //     }
  //   };

  //   if (isOpen && sessionId != null) {
  //     console.log(getMessages());
  //     fetchData();
  //     renderCustomComponent(NewSessionButton, { startNewSession }, false);
  //     interval = setInterval(fetchData, 3000); // Fetch data every 3 seconds
  //   } else {
  //     clearInterval(interval); // Clear interval when the chat window is closed
  //   }

  //   return () => {
  //     clearInterval(interval); // Cleanup interval on component unmount
  //   };
  // }, [isOpen, sessionId]);  
  
  
  useEffect(() => {
    let interval;

    const fetchData = async () => {
      const response = await AskAiService.requestChat(sessionId);
      chatList = response;
      console.log(chatList);
      if (response.length === 0) {
        deleteMessages(); // Clear widget messages

        if (!response.some((chat) => chat.isSentByAi)) {
          console.log("no greet");
          await addGreetingMessage(); // Add greeting if no AI messages yet
        }
      } else {
        var newMessages = [];
        var currentMessages = getMessages().filter(m => m.customId != "newSession");
          response
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .forEach((msg) => {
            const isMessageAlreadyInList = currentMessages.some((chat) => chat.customId === msg.id);
            if (!isMessageAlreadyInList && msg.isToShow) {
              
              newMessages.push({
                type: "text",
                sender: msg.isSentByAi ? MESSAGE_SENDER.RESPONSE : MESSAGE_SENDER.CLIENT,
                showAvatar: true,
                timestamp: new Date(msg.createdAt),
                unread: !isOpen,
                customId: msg.id,
                text: msg.message,
                component: Message
              });
              

              // // Update the widget only if the message is new
              // if (msg.isSentByAi) {
              //   addResponseMessage(msg.message);
              // } else {
              //   addUserMessage(msg.message);
              // }
            }
          });
        console.log("new", newMessages);

        const newSessionButton = {
          type: "component",
          sender: MESSAGE_SENDER.RESPONSE,
          timestamp: new Date(),
          showAvatar: true,
          customId: "newSession",
          unread: true,
          component: NewSessionButton,
          props: {startNewSession}
        };
        setMessages([...currentMessages, ...newMessages, newSessionButton])
      }

    };

    if (sessionId != null) {
      fetchData();
      interval = setInterval(fetchData, 3000); // Fetch data every 3 seconds
    } else {
      clearInterval(interval); // Clear interval when the chat window is closed
    }

    return () => {
      clearInterval(interval); // Cleanup interval on component unmount
    };
  }, [isOpen, sessionId]);  
  

  return (
    <div style={{ textAlign: 'center' }}>
      <button
        onClick={toggleChatWindow}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '0',
          cursor: 'pointer',
          zIndex: 10000000,
          borderRadius: '50%',
          width: '60px',
          height: '60px',
        }}
      >
        {/* Button content (e.g., icon) */}
      </button>

      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="askAi"
        subtitle={"Hi I'm Evie!\n\nTry to ask me about your cases 💭"}
        isOpen={isOpen}
        chatId={sessionId}
        emojis={true}
        showTimeStamp={false}
        showBadge={false}
        showCloseButton={true}
      />
    </div>
  );
};

export default AskAiChatWidget;
