import hubProductIds from "../data/hubProductIds";

const preselectRequirement = (product,selectionId) => {
    if(!product || !selectionId){
        return {};
    }

    if(product.id){
        const productId = product.id.toLowerCase();
        if(productId === hubProductIds.CASTS){
            if(selectionId === hubProductIds.FACIALREFERENCEDIGITALMOUNTINGMODELS){
                const index = product.requirements.findIndex(item => item.description === 'Which cast do you want?');
                product.requirements[index].value = 'Facially Referenced Mounted and Printable Casts';
                return product;
            }
            return product;
        } 
        if(selectionId === hubProductIds.DENTOFACIALANALYSIS){
            const index = product.requirements.findIndex(item => item.description === 'Which Presentation do you want?');
            const values = product.requirements[index].value;
            if(!values.includes('Dentofacial Analysis (DFA)')){
                values.push('Dentofacial Analysis (DFA)');
            }
            product.requirements[index].value = values;
            return product;
        }
    }
    
    
    
}

export default preselectRequirement;